import React, { FC, ReactNode } from 'react';

import { imageRadiusClassName } from './ImageRadius.css';

type Props = {
  children: ReactNode;
};

/**
 * This wrapper will add a border-radius to any images added as a child.
 */
export const ImageRadius: FC<Props> = ({ children }) => <div className={imageRadiusClassName}>{children}</div>;
