import React, { FC } from 'react';

import ReactIs from 'react-is';

import logger from '@common/log';
import { ImageRadius } from '@custom-components/ImageRadius/ImageRadius';
import { Grid } from '@sparky';

import { SplitViewContext } from './SplitViewContext';
import { SplitViewItem } from './SplitViewItem';
import { getGridProperties, getOverlappingGridProperties } from './SplitViewProperties';

export const SplitView: FC<Props> & SplitViewProps = ({
  columnArrangement = '8-4',
  columnAlignment = 'centered',
  children: childrenProps,
}) => {
  const children = React.Children.map(childrenProps, (child, index) => {
    if (index > 1) {
      logger.dev('Error: The `<SplitView>` accepts a maximum of 2 children.');
    }

    if (ReactIs.isElement(child)) {
      return React.cloneElement(child, { order: index + 1 });
    }

    return child;
  });

  if (children?.filter(child => child?.props?.bringToFront)?.length > 1) {
    logger.dev('Error: The `bringToFront` prop should be assigned to 1 or 0 SplitView items.');
  }
  const frontItem = children?.find(child => child?.props?.bringToFront)?.props.order;
  const { gap, alignY } = getGridProperties(columnArrangement, columnAlignment);

  return (
    <SplitViewContext.Provider
      value={{
        columnArrangement,
        columnAlignment,
        hasOverlap: !!frontItem,
      }}>
      <ImageRadius>
        <Grid {...(frontItem ? getOverlappingGridProperties(frontItem) : { columns: '12', gap: gap, alignY: alignY })}>
          {children}
        </Grid>
      </ImageRadius>
    </SplitViewContext.Provider>
  );
};

export type ColumnArrangement = '8-4' | '4-8' | '6-6';
export type ColumnAlignment = 'full' | 'top' | 'centered';

interface Props {
  columnArrangement?: ColumnArrangement;
  columnAlignment?: ColumnAlignment;
  children: JSX.Element[];
}

interface SplitViewProps {
  Item: typeof SplitViewItem;
}

SplitView.Item = SplitViewItem;

export default SplitView;
